















// @vuese
// @group Popup
// Generic Popup
import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'

/**
 * A generic popup that draws the users attention
 * 
 * Useful for when you need to intercept a users journey
 */
@Component
export default class GenericPopup extends Vue {
  /* tslint:disable:no-empty */
  /**
   * Fired when the user tries to close the component
   * @vuese
   */
  @Emit()
  close(){
    return
  }
}
