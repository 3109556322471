



































// @vuese
// @group Forms
// Edit Email
import { Store, TForm } from '@/store/store';
import Component from 'vue-class-component';
import PasswordPopup from '@/components/popup/PasswordPopup.vue'
import GenericForm from '@/components/forms/GenericForm.vue'
import { validateEmailAddress } from '../utils/EmailParser';

@Component({
  components:{
    PasswordPopup,
    GenericForm
  }
})
/**
 * A basic form that allows a user to change their email. 
 * 
 * Because the password needs to be verified to change the email, a popup is used to append the password to the request
 */
export default class EditEmail extends Store {
  email = ''
  errorMessages = {
    message: '',
    errors: {
      new_email: []
    }
  }
  successMessage = ''
  passwordPopupIsVisible? = false

  async created(){
    this.email = (await this.getProfile())?.email || ''
  }

  /**
   * show / hide the password popup
   * @vuese
   */
  showPasswordPopup(force?: boolean) {
    this.clearFormErrors()
    if(force != undefined) {
      this.setSending(force)
    }
    this.passwordPopupIsVisible = force || !this.passwordPopupIsVisible
  }

  /**
   * Submit the request.
   * 
   * If the email is invalid, an alert is rendered to the screen
   * @vuese
   */
  async submit(emailInput: string, password?: string){
    this.showPasswordPopup(false)
    const [email] = validateEmailAddress(emailInput)
    if(!email){
      this.setSending(false)
      return this.$toast.error('Email Is Not Valid')
    }
    if(password){
      
      const args: any = {
        new_email: email,
        password,
      }

      await this.updateEmail(args).then((data)=> {
        this.successMessage = 'Your email was updated. To complete this change please respond to the verification email we have sent'
        this.setSending(false)
        this.$toast.success('Email Updated')
      }).catch(({response}) => {
        this.errorMessages = response.data
        this.setSending(false)
        this.$toast.error('Failed To Update Email')
      })
    }
    else {
      this.setSending(false)
      this.showPasswordPopup(true)
    }
  }

  clearFormErrors(){
    this.successMessage = '';
    this.errorMessages.message = '';
    for(var i in this.errorMessages.errors){
      // @ts-ignore
      this.errorMessages.errors[i] = []
    }
  }
}
