





























import { Store } from '@/store/store';
import Component from 'vue-class-component';
import GenericForm from '@/components/forms/GenericForm.vue';
import { Emit } from 'vue-property-decorator';

interface Profile {
  name: string;
  last_name: string;
}

@Component({
  components:{
    GenericForm
  }
})
export default class EditProfile extends Store {
  
  profile: Profile = {
    name: '',
    last_name: '',
  }

  async created(){
    await this.refresh()
  }
  async refresh(){
    this.profile = await this.getProfile(true)
  }
  
  @Emit()
  async submit(){
    if(this.profile){
      this.setSending(true)
      await this.updateProfile({
        updateProfile:{
          name: this.profile.name,
          last_name: this.profile.last_name
        }
      }).then(() => {
        this.setSending(false)
        this.$toast.success('Profile Updated')
      }).catch(() => {
        this.setSending(false)
        this.$toast.error('Failed To Update Profile')
      })
    }
  }

}
