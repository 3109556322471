























// @vuese
// @group Popup
// Password Popup
import { Component, Emit } from 'vue-property-decorator'
import GenericPopup from '@/components/popup/GenericPopup.vue'
import GenericForm from '@/components/forms/GenericForm.vue'

@Component({
  components: {
    GenericPopup,
    GenericForm
  }
})
/**
 * A popup that contains a form which asks for a users password
 */
export default class PasswordPopup extends GenericPopup {
  password = ''
  /* tslint:disable:no-empty */
  /**
   * Fires when the form is submitted
   * @vuese
   */
  @Emit()
  submit(password: string){
    return password
  }

  /**
   * Fires when a users tries to close the popup
   * @vuese
   */
  @Emit()
  close(){}
}
